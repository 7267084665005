// Copyright © 2021 Move Closer

import Cookies from 'js-cookie'
import {
  ApiConnectorType,
  Authentication,
  AuthServiceType,
  ConnectorMiddleware,
  FoundResource,
  Headers,
  IConnector,
  Injectable,
  Interfaces,
  IResponse,
  IUser,
  Payload
} from '@movecloser/front-core'
import { RequestCall } from '@movecloser/front-core/lib/contracts/connector'

import { performTokenRefresh } from '@module/root/services/refresh'
import { ExternalTokenServiceType, IExternalTokenService } from '@module/root/services/external-token-service'

const TOKEN_REFRESHED = 'tokenRefreshed'
const COOKIE_VALID_MONTHS = 1
const TOKEN_VALID_HOURS = 4

/**
 * Middleware responsible for handling
 * unauthorized responses and refreshing tokens.
 */
@Injectable()
export class UnauthorizedMiddleware implements ConnectorMiddleware {
  private readonly container: Interfaces.Container
  private readonly authService: Authentication<IUser>

  constructor (container: Interfaces.Container) {
    this.authService = container.get(AuthServiceType)
    this.container = container
    window.localStorage.removeItem(TOKEN_REFRESHED)
  }

  /**
   * After call middleware action.
   * @param response
   * @param resource
   * @param requestCall
   */
  public async afterCall (response: IResponse, resource: FoundResource, requestCall: RequestCall): Promise<IResponse> {
    const externalTokenService: IExternalTokenService = this.container.get(ExternalTokenServiceType)

    if (response.status === 401 && externalTokenService.hasToken()) {
      const newToken = await performTokenRefresh(Cookies.get('refresh_token') as string)
      const { _expiresAt } = this.updateCookies(newToken.accessToken, newToken.refreshToken)
      externalTokenService.setStored({ token: newToken.accessToken, tokenExpires: _expiresAt })

      const retriedResponse: IResponse | void = await this.retryOriginalCall(newToken.accessToken, requestCall, Boolean(resource.auth))
      return retriedResponse || response
    }

    return response
  }

  /**
   * Before call middleware action.
   * @param resource
   * @param headers
   * @param body
   */
  public beforeCall (resource: FoundResource, headers: Headers, body: Payload): { body: Payload; headers: Headers } {
    return {
      body,
      headers: {
        ...headers,
        Authorization: `${Cookies.get('token')}`
      }
    }
  }

  /**
   * Match domain to specific environment
   * @private
   */
  private getDomain (): string {
    let domain: string
    const env = process.env.VUE_APP_ENV

    switch (env) {
      case 'local':
        domain = 'localhost'
        break
      case 'stage':
        domain = '.movecloser.dev'
        break
      default:
        domain = '.radio357.pl'
    }

    return domain
  }

  /**
   * Trigger refresh of authorization data stored in cookies.
   * @param accessToken
   * @param refreshToken
   * @private
   */
  private updateCookies (accessToken: string, refreshToken: string) {
    const date = new Date()
    date.setHours(date.getHours() + TOKEN_VALID_HOURS)
    const time = date.getTime()
    const timestamp = Math.round(time / 1000) * 1000

    const expires = new Date()
    expires.setMonth(expires.getMonth() + COOKIE_VALID_MONTHS)
    const expiresDate = expires.toUTCString()

    const domain = this.getDomain()

    this.writeCookie(`token=${accessToken}`, domain, expiresDate)
    this.writeCookie(`token_expires=${timestamp}`, domain, expiresDate)
    this.writeCookie(`refresh_token=${refreshToken}`, domain, expiresDate)
    this.writeCookie(`refresh_token_expires=${timestamp}`, domain, expiresDate)

    return {
      _expiresAt: expires.getTime(),
      _accessToken: accessToken,
      _refreshToken: refreshToken
    }
  }

  /**
   * Write cookie in browser
   * @param value
   * @param domain
   * @param expires
   * @private
   */
  private writeCookie (value: string, domain: string, expires: string) {
    document.cookie = `${value};domain=${domain};path=/;expires=${expires}`
  }

  /**
   * Retry failed call with newly obtained token
   * @param token
   * @param requestCall
   * @param useAuth
   * @private
   */
  private async retryOriginalCall (token: string, requestCall: RequestCall, useAuth: boolean): Promise<IResponse|void> {
    if (window.localStorage.getItem(TOKEN_REFRESHED) === 'true') {
      return
    }

    try {
      window.localStorage.setItem(TOKEN_REFRESHED, 'true')
      const connector: IConnector = this.container.get(ApiConnectorType)
      return await connector.call(
        requestCall.resource,
        requestCall.action,
        requestCall.params,
        requestCall.body,
        useAuth ? {
          ...requestCall.headers,
          Authorization: `Bearer ${token}`
        } : requestCall.headers,
        requestCall.responseType
      )
    } catch (error) {
      this.authService.deleteToken()
      throw error
    } finally {
      window.localStorage.removeItem(TOKEN_REFRESHED)
    }
  }
}
