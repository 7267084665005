// Copyright © 2021 Move Closer

import 'reflect-metadata'
import Vue from 'vue'
import VueMeta from 'vue-meta'

import Configuration from '@plugin/configuration'
import { createApp } from '@/bootstrap/app'
import { installContainer } from '@/shared/plugins/inversify'
import { registerExtensions } from '@/shared/extensions'

import { IdentityService, IdentityServiceType } from '@service/identity'

import { i18n } from '@/shared/support/i18n'

import './assets/scss/main.scss'
import App from './App.vue'
import Cookies from 'js-cookie'

Vue.use(Configuration)
Vue.use(VueMeta)

installContainer(Vue)
registerExtensions(Vue)

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Kararzyna Otto <katarzyna.otto@movecloser.pl>
 */
createApp().then(core => {
  const { configuration, container, router } = core

  // In the local environment mock token in localStorage
  // if (process.env.VUE_APP_ENV === 'local') {
  //   Cookies.set('token', process.env.VUE_APP_STORAGE_TOKEN ? process.env.VUE_APP_STORAGE_TOKEN : '')
  //   Cookies.set('token_expires', process.env.VUE_APP_STORAGE_TOKEN_EXPIRES ? process.env.VUE_APP_STORAGE_TOKEN_EXPIRES : '')
  // }

  const identity = container.get<IdentityService>(IdentityServiceType)
  identity.generateSignature()

  new Vue({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    router,
    container,
    configuration,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
