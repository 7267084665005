import { Component, Vue } from 'vue-property-decorator'

import { Spinner } from '@component/Spinner'
import { Picture } from '@component/Picture'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'FullScreenLoader',
  components: { Spinner, Picture },
  template: `
    <div class="full-screen-loader" >
      <Picture class="full-screen-loader__img" alt="Radio357" source="/logoR357.svg" />
      <Spinner size="large" />
    </div>
  `
})
export class FullScreenLoader extends Vue {
}
