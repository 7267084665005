// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

export const resources: ResourcesRegistry = {
  chart: {
    prefix: 'api',
    methods: {
      load: {
        url: 'charts/current/{slug}',
        params: ['slug'],
        method: Methods.Get,
        auth: false
      },
      loadLast: {
        url: 'charts/latest/{slug}',
        params: ['slug'],
        method: Methods.Get,
        auth: false
      },
      loadChart: {
        url: 'charts/{slug}/{chartNo}',
        params: ['chartNo', 'slug'],
        method: Methods.Get,
        auth: false
      },
      loadVotes: {
        url: 'me/charts/{chartId}/votes',
        params: ['chartId'],
        method: Methods.Get,
        auth: true
      },
      validate: {
        url: 'charts/{chartId}/vote?validateOnly=1',
        params: ['chartId'],
        method: Methods.Post,
        auth: true
      },
      vote: {
        url: 'charts/{chartId}/vote',
        params: ['chartId'],
        method: Methods.Post,
        auth: true
      }
    }
  }
}
