// Copyright © 2021 Move Closer

import { mapCollection, MappingConfig, MappingTypes } from '@movecloser/front-core'
import { trackAdapterMap } from '@module/voting/models/track.adapter'
import { votetrackAdapterMap } from '@module/voting/models/votetrack.adapter'

/**
 * @author olga Milczek <olga.milczek@movecloser.pl>
 */
export const chartAdapterMap: MappingConfig = {
  announcementAt: 'announcement_at',
  capacity: 'capacity',
  endsAt: 'ends_at',
  id: 'id',
  features: 'features',
  maxVotesPerUser: 'max_votes_per_user',
  minVotesPerUser: 'min_votes_per_user',
  name: 'name',
  no: 'no',
  publishedAt: 'published_at',
  results: {
    type: MappingTypes.Function,
    value: item => {
      if (!item.results) {
        return null
      }

      return {
        waitingRoom: item.results.waitingRoom ? mapCollection(item.results.waitingRoom, trackAdapterMap) : [],
        mainChart: item.results.mainChart ? mapCollection(item.results.mainChart, trackAdapterMap) : []
      }
    }
  },
  rules: 'rules',
  startsAt: 'starts_at',
  status: 'status',
  tracks: {
    type: MappingTypes.Function,
    value: item => {
      if (!item.tracks) {
        return null
      }
      return item.tracks ? mapCollection(item.tracks, votetrackAdapterMap, false) : []
    }
  },
  waitingRoomCapacity: 'waiting_room_capacity',
  waitingRoomName: 'waiting_room_name'
}
