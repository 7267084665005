import { AbstractTheme, ThemeRegistry } from './theme.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const getThemeClass = <Theme extends string|number|symbol = AbstractTheme> (theme: Theme, classRegistry: ThemeRegistry<Theme>): string => {
  if (typeof classRegistry[theme] !== 'string') {
    if (!Object.keys(classRegistry).length) {
      console.warn('getThemeClass(): Provided registry does NOT contain any definition. Used no class.')
    }

    console.warn(
      `getThemeClass(): Provided registry does NOT contain the given key.\nFalling back to the first key in the set, which is [${Object.keys(classRegistry)[0]}].`
    )

    return Object.values(classRegistry)[0] as string
  }

  return classRegistry[theme]
}
