// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  IConfiguration,
  IConnector,
  Module
} from '@movecloser/front-core'

import { IRadioUserRepository, RadioUserRepositoryType } from '@module/voting/contracts'
import { AuthRepositoryType, IAuthRepository } from './contracts'
import { IExternalTokenService, ExternalTokenService, ExternalTokenServiceType } from './services/external-token-service'
import { AuthRepository } from './repositories/auth'
import { resources } from './resources'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@AppModule({
  name: 'root',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind) => {
      bind<IAuthRepository>(AuthRepositoryType).to(AuthRepository)
      bind<IExternalTokenService>(ExternalTokenServiceType).to(ExternalTokenService)
      bind<IRadioUserRepository>(RadioUserRepositoryType).to(AuthRepository)
    }
  },
  boot: container => {
    const connector = container.get<IConnector>(ApiConnectorType)
    connector.useResources(
      resources
    )
  }
})
export class RootModule extends Module {}
