// Copyright © 2021 Move Closer

import { InfoTypes } from './ChartInfo.contracts'

/**
 * Default object to get info to display form API
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const infoConfig: Record<InfoTypes, string> = {
  // Ordered by display order
  [InfoTypes.Name]: '',
  [InfoTypes.No]: '',
  [InfoTypes.StartAt]: '',
  [InfoTypes.EndedAt]: '',
  [InfoTypes.AnnouncementAt]: '',
  [InfoTypes.MaxVotes]: ''
}
