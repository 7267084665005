// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  ConnectorMiddleware, DateTime, DateTimeType, IDateTime,
  Interfaces,
  ProvidersFactory
} from '@movecloser/front-core'

import {
  IdentityMiddleware,
  IdentityMiddlewareType,
  IdentityService,
  IdentityServiceType,
  IIdentityService
} from '@service/identity'
import { IProcessService, ProcessService, ProcessServiceType } from '@service/process-service'
import { UnauthorizedMiddleware, UnauthorizedMiddlewareType } from '@service/unauthorize'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const services: ProvidersFactory = config => {
  return (bind: Interfaces.Bind) => {
    bind<IIdentityService>(IdentityServiceType).to(IdentityService).inSingletonScope()

    bind<IDateTime>(DateTimeType).toDynamicValue(
      context => new DateTime()
    )

    bind<ConnectorMiddleware>(IdentityMiddlewareType).toDynamicValue(
      context => new IdentityMiddleware(context.container.get(IdentityServiceType))
    ).inSingletonScope()

    bind<ConnectorMiddleware>(UnauthorizedMiddlewareType).toDynamicValue(
      context => new UnauthorizedMiddleware(context.container)
    ).inSingletonScope()

    bind<IProcessService>(ProcessServiceType).toDynamicValue(context => {
      return new ProcessService(
        context.container.get(ApiConnectorType),
        config.byKey<number[]>('app.processDelays') ?? []
      )
    }
    ).inSingletonScope()
  }
}
