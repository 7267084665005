// Copyright © 2021 Move Closer

import { MagicModel, IUser } from '@movecloser/front-core'

import { Chart } from '../models/chart'

export interface Archive {
  [key: number]: string
}

export interface ArchiveLabels {
  label: string | null
  value: string[] | null
}

export interface ChartData {
  chart: ChartInfo
  results: Archive
}

export enum ChartFeature {
  Sorting = 'sorting',
}

export interface ChartInfo {
  features: ChartFeature[]
  announcementAt: string
  capacity: number
  endsAt: string
  id : number
  maxVotesPerUser: number
  minVotesPerUser: number
  name: string
  no: number
  results: Results | null
  startsAt: string
  status: string
  tracks: TrackData[]
  waitingRoomCapacity: number
  waitingRoomName: number
}

export type ChartModel = MagicModel<ChartData, Chart>

export interface IRadioUser extends IUser {
  isPatron: boolean
  privileged: boolean
}

export interface Results {
  mainChart: TrackData[]
  waitingRoom: TrackData[]
}

export interface Track {
  artist: string
  id: string
  mp3Url: string
  name: string
}

export interface TrackData {
  artist: string
  id: number
  isNew?: boolean
  lastPosition?: number
  name: string
  place?: number
  position: number
  timesOnChart?: number
  chartAppearances: number
}
