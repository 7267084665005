// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Badge } from '@component/Badge'

import { TrackData } from '../../contracts'

/**
 * Single Chart Item
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ChartItem',
  components: { Badge },
  template: `
    <div class="chart-item" :class="[{'--podium': isPodiumItem }, {'--waitingroom-element': isWaitingList}]">
    <!-- Chart Item TOP -->
    <span class="chart-item__position" :class="{'--podium': isPodiumItem }">{{ item.position }}</span>
    <div>
      <!-- Song info -->
      <span class="chart-item__author" :class="{'--podium': isPodiumItem }">{{ item.artist }}</span>
      <span class="chart-item__title" :class="{'--podium': isPodiumItem }">{{ item.name }}</span>
    </div>

    <div v-if="!minimalist" class="text-right">
          <span v-if="!item.isNew" class="chart-item__change-position" :class="positionChangeClass">
            {{ typeof positionChange === 'number' && positionChange > 0 ? '+' + positionChange : positionChange }}
          </span>
      <!-- Song info | END -->
    </div>

    <div v-if="!minimalist" class="chart-item__info">
      <Badge v-if="item.isNew">
        {{ $t('chart.position.new').toUpperCase() }}
      </Badge>

      <template v-else>
        <span>{{ $t('chart.item.lastPosition', { lastPosition: item.lastPosition }) }}</span>
        &nbsp;&nbsp;
        <span v-if="!isWaitingList">{{ $t('chart.item.weeks', { weeks: item.timesOnChart }) }}</span>
      </template>
    </div>
    </div>
  `
})
export class ChartItem extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isWaitingList!: boolean

  @Prop({ type: Object, required: true })
  public readonly item!: TrackData

  @Prop({ type: Boolean, required: false, default: false })
  public readonly minimalist!: boolean

  public get hasChangedPosition (): boolean {
    if (this.minimalist || this.item.isNew) {
      return false
    }
    return this.item.lastPosition !== this.item.position
  }

  public get isPodiumItem (): boolean {
    return !this.minimalist && this.item.position < 4
  }

  public get positionChange (): number | string {
    if (!this.item.lastPosition || !this.hasChangedPosition) {
      return this.$t('chart.position.without-changes') as string
    }
    return this.item.lastPosition - this.item.position
  }

  public get positionChangeClass (): string {
    if (!this.hasChangedPosition) {
      return 'text-dark'
    }
    if (this.positionChange > 0) {
      return 'text-success'
    }
    return 'text-danger'
  }
}
