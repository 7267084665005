

import { Component, Prop, Vue } from 'vue-property-decorator'

import { Button } from '@component/Button'
import { Error } from '@component/Error/Error'
import { Theme } from '@component/_composables'

@Component({
  name: 'VotingError',
  components: { Error, Button }
})
export class VotingError extends Vue {
  @Prop({ type: String, required: false })
  public message!: string

  public readonly mainPageLink = this.$configuration ? this.$configuration.byKey('app.mainPage') : ''

  public readonly theme = Theme
}

export default VotingError
