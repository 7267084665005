import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { Button } from '../Button'
import { Icon, IconName } from '../Icon'
import { Picture } from '../Picture'
import { Link } from '../Link'
import { Select } from '../Select'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Header',
  components: { Button, Icon, Picture, Link, Select },
  template: `
    <header class="header bg-primary">
        <div class="header__top d-flex justify-content-between">
          <Link target="https://radio357.pl/" class="text-body">
            <Picture class="header__img" alt="Radio357" source="/logoR357.svg" />
          </Link>

          <Link target="https://radio357.pl/" class="text-body">
            <Icon :name="iconsName.X" size="large" />
          </Link>
        </div>

        <div class="row">
          <div class="d-none d-lg-block col-lg-2"></div>
          <h1 class="header__heading col-12 col-lg-10">
            <span v-if="voting" class="header__heading-voting">{{ $t('header.voting', { voting } )}}</span>
            <span class="header__heading-title">{{ name }}</span>
          </h1>
        </div>

        <div class="row header__bottom">
          <div class="d-none d-lg-block col-lg-2"></div>
          <div class="col-12 col-lg-5 d-flex justify-content-between align-items-end">
            <Button :icon="{ name: info ? iconsName.ChevronUp : iconsName.ChevronDown }"
                    variant="none" :label="$t('header.info')"
                    @click="toggleInfo" />
            <span v-if="time" class="header__time-left">{{ $t('header.remaining', { time: time } )}}</span>
          </div>
        </div>
    </header>
  `
})
export class Header extends Vue {
  @PropSync('openInfo', { type: Boolean, required: true })
  public info!: boolean

  @Prop({ type: String, required: false })
  public readonly endsAt!: string | undefined

  @Prop({ type: String, required: true })
  public readonly name!: string

  @Prop({ type: Number, required: true })
  public readonly voting!: number

  public toggleInfo (): void {
    this.info = !this.info
  }

  public readonly iconsName = IconName
  public readonly millisecondsInHour = 60 * 60 * 1000
  private timer?: NodeJS.Timeout
  private now: number = Date.now()

  public get time (): string | undefined {
    if (!this.endsAt || typeof this.endsAt === undefined) {
      return
    }

    const remainingTime = new Date(this.endsAt).getTime() - this.now

    if (Math.round(remainingTime / 1000) === 0) {
      location.reload()
      return
    }

    if (remainingTime <= 0) {
      return
    }

    const hours = Math.floor(remainingTime / this.millisecondsInHour)

    if (hours > 24) {
      const d = Math.floor(hours / 24)
      const h = hours - (d * 24)

      return `${this.$tc('_.days', d)} ${h} h`
    }

    if (hours === 0) {
      const minutes = Math.floor(remainingTime / 60 / 1000)

      if (minutes > 0) {
        return `${Math.round(remainingTime / 60 / 1000)} min`
      } else {
        return `${Math.round(remainingTime / 1000)} s`
      }
    }

    if (isNaN(hours)) {
      return
    }

    return `${hours} h`
  }

  created (): void {
    this.now = Date.now()
    this.timer = setInterval(this.updateTime, 500)
  }

  beforeDestroy (): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  private updateTime (): void {
    this.now = Date.now()
  }
}
