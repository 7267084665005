import { Size, SizeRegistry } from './size.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const getSizeClass = (size: Size, sizeClassRegistry: SizeRegistry): string => {
  if (typeof sizeClassRegistry[size] !== 'string') {
    if (!Object.keys(sizeClassRegistry).length) {
      console.warn('getSizeClass(): Provided registry does NOT contain any definition. Used no class.')
    }

    console.warn(
      `getSizeClass(): Provided registry does NOT contain the given key.\nFalling back to the first key in the set, which is [${Object.keys(sizeClassRegistry)[0]}].`
    )

    return Object.values(sizeClassRegistry)[0] as string
  }

  return sizeClassRegistry[size] as string
}
