import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Link',
  template: `
    <component v-else :is="disabled ? 'span' : 'a'" :class="{ 'text-muted': disabled }"
               v-bind="disabled ? {} : { href: target, target: aTarget }" >
        <slot/>
    </component>
  `
})
export class Link extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly newTab!: boolean

  @Prop({ type: String, required: true })
  public readonly target!: string

  public get aTarget (): string {
    return this.newTab ? '_blank' : '_self'
  }
}
