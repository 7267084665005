
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Layout } from '@component/Layout'
import { Picture } from '@component/Picture'
import { Select } from '@component/Select'

import { Archive, ChartInfo, ArchiveLabels, TrackData } from '../contracts'
import { ChartList } from '../components/ChartList'
import { ChartSummary } from '../components/ChartSummary'

/**
 * View displaying Chart
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<ChartView>({
  name: 'ChartView',
  components: { ChartList, ChartSummary, Layout, Picture, Select }

})
export class ChartView extends Vue {
  @Prop({ type: Object, required: false })
  public archives?: Archive

  @Prop({ type: Object, required: true })
  public readonly chart!: ChartInfo

  public archive: ArchiveLabels[] = []

  public currentArchive: ArchiveLabels = { value: null, label: null }

  public get mainChart (): TrackData[] {
    if (!this.chart?.results) {
      return []
    }
    return this.chart.results.mainChart
  }

  public get mainImg (): string {
    return require('../../../assets/images/photos/salto/salto.png')
  }

  public get waitingChart (): TrackData[] {
    if (!this.chart.results) {
      return []
    }
    return this.chart.results.waitingRoom
  }

  public get waitingImg (): string {
    return require('../../../assets/images/photos/czapka.png')
  }

  created (): void {
    this.createArchiveUrls()
  }

  private createArchiveUrls (): void {
    const param = this.$route.params.slug

    if (this.archives) {
      for (const item in this.archives) {
        const data = [param, item]
        const label = `${this.$t('header.voting', { item })}` + item + ' - ' + this.archiveDate(this.archives[item])

        this.archive.push({ value: data, label: label })

        if (this.$route.params.no === item) {
          this.currentArchive = { value: data, label: label }
        }
      }
      if (this.$route.name === 'voting.lastResults') {
        this.currentArchive = this.archive[this.archive.length - 1]
      }

      this.archive = this.reverseArchive(this.archive, this.archive.length)
    }
  }

  private archiveDate (value: string): string {
    const date = value.substr(0, 10).replace(/-/g, '.')
    return date.split('.').reverse().join('.')
  }

  protected reverseArchive (value: ArchiveLabels[], length: number): ArchiveLabels[] {
    const tmpArr: ArchiveLabels[] = []

    for (let i = length - 1; i >= 0; i--) {
      tmpArr.push(value[i])
    }

    return tmpArr
  }
}

export default ChartView
