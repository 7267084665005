// Copyright © 2021 Move Closer

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { BackgroundTheme } from '@component/_composables'
import { Search } from '@component/Search'
import { Spinner } from '@component/Spinner'
import { List, ListItem } from '@component/List'

import { ChartFeature, Track, TrackData } from '../../contracts'
import { VotingItem } from '../VotingItem'
import { VotingSingleRow } from '../VotingSingleRow'

enum Sort {
  Alphabetically = 'Alphabetically',
  BySeniority = 'BySeniority'
}

/**
 * Component to display voting list
 */
@Component({
  name: 'VotingList',
  components: { VotingItem, VotingSingleRow, Search, List, Spinner },
  template: `
    <div class="vote-list">
      <div v-if="isSortEnabled" class="vote-list__filters">
        <span>
          {{ $t('_.order') }}
        </span>

        <div class="vote-list__sort">
          <button class="vote-list__sort-item" @click="handleSort(Sort.BySeniority)" :class="{'vote-list__sort-item--active': sort === Sort.BySeniority}">
            {{ $t('_.BySeniority')}}
          </button>

          <button class="vote-list__sort-item" @click="handleSort(Sort.Alphabetically)" :class="{'vote-list__sort-item--active': sort === Sort.Alphabetically}">
            {{ $t('_.Alphabetically')}}
          </button>
        </div>
      </div>

      <Search v-if="isSearchable"
              class="vote-list__search"
              :query="query"
              @applyFiltering="onFilterApply"
              @cleanFiltering="onFilterClean"/>

      <div v-if="hasNoSearchResults()" class="vote-list__no-result">
        <p v-html="$t('_.search-no-result', { query })"/>
      </div>

      <List v-else :theme="listTheme" :list="searchedList" :loading="loading" withBorder>
        <template v-slot="item" v-slot:index="idx">
            <VotingSingleRow
              :ref="item.isLast ? 'lastVotingRow' : null"
              :activeVoting="activeVoting"
              :alreadyVoted="alreadyVoted"
              :confirmation="confirmation"
              :disabled="shouldBeDisabled(item.data.id)"
              :item="item.data"
              :itemActive="item.active"
              :itemBackgroundColor="item.backgroundColor"
              :itemDisabled="item.disabled"
              :itemIsAction="item.isAction"
              :value="itemsState[item.data.id]"
              @update:value="onCheckUpdate(item.data, $event)"
              @update-audio="playAudio"
            />
        </template>
      </List>

      <div id="endListObserver" style="height: 1rem"></div>
    </div>
  `
})
export class VotingList extends Vue {
  $refs!: {
    lastVotingRow: Vue
  }

  public hasNoSearchResults (): boolean {
    return this.isSearched &&
      this.searchedList.length === 0 &&
      !this.confirmation
  }

  protected observer!: IntersectionObserver

  public mounted (): void {
    this.setChunkSize(3000)
    this.setListSize()

    this.observeVotingRow()
  }

  protected chunkSize = 0
  public listSize = 0

  // Assumption that tile height is 75px
  protected assertTileSize = 75

  // Use list size if provided. Otherwise - use dynamic list based on screen and tile size.
  private setChunkSize (listSize: number | false): void {
    if (!listSize) {
      this.chunkSize = Math.ceil(window.innerHeight / this.assertTileSize) * 2
    }

    this.chunkSize = listSize as number
  }

  private setListSize (): void {
    this.listSize = this.chunkSize
  }

  protected async observeVotingRow (): Promise<void> {
    await this.$nextTick()

    if (!this.observer) {
      this.observer = new IntersectionObserver(this.onLastVotingElementVisible.bind(this))
    }

    if (!this.$refs.lastVotingRow) {
      return
    }

    const observerElement: Element = document.getElementById('endListObserver') as Element
    this.observer.observe(observerElement)
  }

  public onLastVotingElementVisible (entries: IntersectionObserverEntry[]): void {
    if (this.listSize >= this.list.length) {
      return
    }

    if (entries[0].intersectionRatio > 0) {
      this.listSize += this.chunkSize
    }

    this.observeVotingRow()
    this.isFooterVisible()
  }

  public isFooterVisible (): void {
    const footer = document.getElementById('footer')
    if (!footer) return

    footer.style.display = 'none'

    if (this.listSize > this.list.length - (this.chunkSize / 2)) {
      footer.style.display = 'block'
    }
  }

  public itemsState: Record<number, boolean> = {}
  public chosenTracks: Record<number, TrackData> = {}

  public onCheckUpdate (track: TrackData, value: boolean): void {
    this.itemsState[track.id] = value
    if (value) {
      this.chosenTracks[track.id] = track
    } else {
      delete this.chosenTracks[track.id]
    }

    this.$emit('update:model', Object.keys(this.itemsState).map((key: string) => parseInt(key)).filter((key: number) => this.itemsState[key] === true))
    this.$emit('tracks', Array.from(Object.values(this.chosenTracks)))
  }

  @Prop({ type: Array, required: true })
  public model!: number[]

  @Prop({ type: Boolean, required: true })
  public readonly activeVoting!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly alreadyVoted!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly confirmation!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isSearchable!: boolean

  @Prop({ type: Array, required: true })
  public readonly list!: TrackData[]

  @Prop({ type: String, required: false, default: BackgroundTheme.White })
  public readonly listTheme!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  @Prop({ type: Number, required: true })
  public readonly maxVotes!: number

  @Prop({ type: Array, required: false, default: () => [] })
  public readonly features!: ChartFeature[]

  public isSearched: boolean = false
  public query: string = ''
  public activePlayer: string | null = null
  public prevPlayerId: HTMLAudioElement | string | null = null

  @Watch('confirmation')
  onConfirmationChanged (): void {
    this.query = ''
    this.listSize = this.chunkSize
  }

  public Sort = Sort

  public isSortEnabled = this.features.includes(ChartFeature.Sorting)

  public sort = this.isSortEnabled ? Sort.BySeniority : Sort.Alphabetically

  public handleSort (sort: Sort): void {
    this.sort = sort
    this.$forceUpdate()
  }

  public get searchedList (): ListItem[] {
    this.observeVotingRow()

    let searched: TrackData[]

    if (!this.query) {
      searched = this.list
    } else {
      searched = this.list.filter(item => this.includeFilterQuery(item))
    }

    let list = this.confirmation ? this.list : searched

    if (this.sort === Sort.BySeniority) {
      list = list.sort((a, b) => {
        return a.chartAppearances - b.chartAppearances || a.artist.localeCompare(b.artist)
      })
    } else {
      list = list.sort((a, b) => {
        return a.artist.localeCompare(b.artist)
      })
    }

    return list
      .map((i, idx) => {
        return {
          data: i,
          backgroundColor: this.model.includes(i.id) && this.activeVoting ? BackgroundTheme.White : undefined,
          isAction: this.activeVoting,
          isLast: (idx === this.listSize - 1) || (idx === list.length - 1)
        }
      }).slice(0, this.listSize)
  }

  public shouldBeDisabled (itemId: number): boolean {
    return this.hasMaxVotes && !this.model.includes(itemId)
  }

  public get hasMaxVotes (): boolean {
    return this.model.length === this.maxVotes
  }

  public onFilterApply (value: string): void {
    this.query = value
    this.isSearched = true
  }

  public onFilterClean (): void {
    this.query = ''
    this.isSearched = false
  }

  protected includeFilterQuery (item: TrackData): boolean {
    const q = this.query.toLowerCase()
    return item.name.toLowerCase().includes(q) || item.artist.toLowerCase().includes(q)
  }

  public playAudio (value: Track): void {
    if (value.id) {
      const audio: HTMLAudioElement | null = document.getElementById('audio-' + value.id) as HTMLAudioElement
      const radio: HTMLFormElement | null = document.getElementById('radio-' + value.id) as HTMLFormElement

      if (this.prevPlayerId !== null) {
        const prevAudio: HTMLAudioElement = document.getElementById('audio-' + this.prevPlayerId) as HTMLAudioElement
        const prevRadio: HTMLFormElement | null = document.getElementById('radio-' + this.prevPlayerId) as HTMLFormElement

        if (prevAudio) {
          prevAudio.pause()
          prevAudio.currentTime = 0
        }

        if (prevRadio) {
          prevRadio.checked = false
        }
      }

      if (audio) {
        audio.pause()

        if (value.id !== this.activePlayer) {
          this.activePlayer = value.id
          audio.src = value.mp3Url
          audio.play().then(() => {
            audio.addEventListener('ended', () => {
              this.resetActivePlayer(audio, radio)
              audio.removeEventListener('ended', () => {
                this.resetActivePlayer(audio, radio)
              })
            })
          })
          if (radio) {
            radio.checked = true
          }
        } else {
          this.resetActivePlayer(audio, radio)
          audio.pause()
        }
      }
      this.prevPlayerId = value.id
    }
  }

  protected resetActivePlayer (audio: HTMLAudioElement, radio: HTMLFormElement): void {
    this.activePlayer = null
    audio.src = ' '
    radio.checked = false
  }
}
