
import { Component, Vue } from 'vue-property-decorator'
import { AnyObject, Authentication, AuthServiceType, IUser } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { Inject } from '@plugin/inversify'
import { FullScreenLoader } from '@component/FullScreenLoader'
import { Footer } from '@component/Footer'

import { AuthRepositoryType, IAuthRepository } from '@module/root/contracts'
import { IRadioUser } from '@module/voting/contracts'
import { ExternalTokenServiceType, IExternalTokenService } from '@module/root/services/external-token-service'

import { MainView } from '@module/voting/views/MainView.vue'

@Component<App>({
  name: 'App',
  components: {
    FullScreenLoader,
    MainView,
    Footer
  },

  metaInfo (): MetaInfo {
    if (this.$route.name) {
      return {
        title: `${this.$t(this.$route.name, { number: this.$route.params.no })} | Radio 357`
      }
    }
    return {}
  },
  mounted () {
    this.initApp()
  }
})
export default class App extends Vue {
  @Inject(AuthServiceType)
  private authService!: Authentication<IUser>

  @Inject(AuthRepositoryType)
  private authRepository!: IAuthRepository

  @Inject(ExternalTokenServiceType)
  private externalTokenService!: IExternalTokenService

  public loading = true
  public isPatron: boolean = false

  public async initApp (): Promise<void> {
    if (this.externalTokenService.hasToken()) {
      const token = this.externalTokenService.getToken()

      if (token) {
        this.authRepository.check(token.accessToken).then((res: IRadioUser) => {
          this.authService.setToken(token)
          this.isPatron = res.isPatron

          const user = this.externalTokenService.getUser()
          if (user) {
            this.authService.setUser(user)
          }
        }).catch((err: AnyObject) => {
          console.error({ err })
          // this.authService.deleteToken()
        }).finally(() => {
          this.loading = false
        })
      }
      return
    }
    this.loading = false
  }
}
