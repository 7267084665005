// Copyright © 2021 Move Closer

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const app = {
  mainPage: process.env.VUE_APP_RADIO357_MAIN_PAGE,
  processDelays: [1, 1, 1, 3, 3, 3, 10, 10, 10, 20]
}
