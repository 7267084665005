import { Theme, SizeMap, SizeRegistry, ThemeRegistry } from '../_composables'

import { ButtonVariant } from './Button.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const buttonSizeRegistry: SizeRegistry = {
  [SizeMap.Large]: 'btn-lg',
  [SizeMap.Medium]: '',
  [SizeMap.Small]: 'btn-sm',
  [SizeMap.XLarge]: 'btn-lg',
  [SizeMap.XSmall]: 'btn-sm'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const buttonThemeClassRegistry: ThemeRegistry<Theme> = {
  [Theme.Default]: '-primary',
  [Theme.Danger]: '-danger',
  [Theme.Dark]: '-dark',
  [Theme.Info]: '-info',
  [Theme.Light]: '-light',
  [Theme.Primary]: '-primary',
  [Theme.Success]: '-success',
  [Theme.Warning]: '-warning'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const buttonVariantClassRegistry: ThemeRegistry<ButtonVariant> = {
  [ButtonVariant.Default]: '',
  [ButtonVariant.Icon]: '-icon',
  [ButtonVariant.Outline]: '-outline',
  [ButtonVariant.None]: '-none'
}
