// Copyright © 2021 Move Closer

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

/**
 * Creates router instance with all add-ons.
 */
export const createRouter = (
  routes: RouteConfig[]
): VueRouter => {
  const baseUrl =
    process.env.VUE_APP_BASE_URL && typeof process.env.VUE_APP_BASE_URL === 'string' && process.env.VUE_APP_BASE_URL.length > 0
      ? process.env.VUE_APP_BASE_URL : process.env.BASE_URL

  const router = new VueRouter({
    base: baseUrl,
    mode: 'history',
    routes
  })

  return router
}
