// Copyright © 2021 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { VotingItem } from '@module/voting/components/VotingItem'
import { Search } from '@component/Search'
import { List } from '@component/List'
import { Spinner } from '@component/Spinner'
import { TrackData } from '@module/voting/contracts'
import { BackgroundTheme, backgroundThemeClassRegistry, getThemeClass } from '@component/_composables'

/**
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */

@Component({
  name: 'VotingSingleRow',
  components: { VotingItem, Search, List, Spinner },
  template: `
    <div class="d-flex align-items-center list-single-row" :class="[itemClass]">
      <div class="player">
        <audio v-if="isPlayerShown(item.mp3Url)" :id="'audio-' + item.id">
          <source preload="none" type="audio/mpeg">
        </audio>

        <input v-if="isPlayerShown(item.mp3Url)" :id="'radio-' + item.id" type="radio" class="player--input player--hidden" v-model="activePlayer" :value="item.id"/>

        <div v-if="isPlayerShown(item.mp3Url)" v-on:click="emitAudioId(item)" class="player--icon"></div>
      </div>

      <VotingItem :value.sync="_model" :activeVoting="activeVoting" :item="item" :disabled="disabled"/>
    </div>
  `
})

export class VotingSingleRow extends Vue {
  @PropSync('value', { type: Boolean, required: false, default: false })
  public _model!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly activeVoting!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly alreadyVoted!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly confirmation!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly disabled!: boolean

  @Prop({ type: Object, required: true })
  public readonly item!: TrackData

  @Prop({ type: Boolean, required: false })
  public readonly itemActive!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly itemIsAction!: boolean

  @Prop({ type: String, required: false })
  public readonly itemBackgroundColor!: BackgroundTheme

  @Prop({ type: Boolean, required: false })
  public readonly itemDisabled!: boolean

  public activePlayer: string | null = null
  public prevPlayerId: HTMLAudioElement | string | null = null

  public get getItemBackground (): string {
    if (!this.itemBackgroundColor) {
      return ''
    }
    return getThemeClass<BackgroundTheme>(this.itemBackgroundColor, backgroundThemeClassRegistry)
  }

  public get itemClass (): string {
    let name = ''

    if (this.itemBackgroundColor) {
      name += this.getItemBackground
    }

    if (this.itemIsAction) {
      name += ' list-group-item-action'
    }

    if (this.itemActive) {
      name += ' active'
    }

    if (this.itemDisabled) {
      name += ' disabled'
    }

    return name
  }

  public emitAudioId (value: HTMLAudioElement): void {
    this.$emit('update-audio', value)
  }

  protected isPlayerShown (playerElement: string): boolean {
    if (playerElement) {
      return !this.confirmation && !this.alreadyVoted
    } else {
      return false
    }
  }
}
