// Copyright © 2021 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { Check } from '@component/Check'

import { TrackData } from '../../contracts'

/**
 * Single item from voting list
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'VotingItem',
  components: { Check },
  template: `
    <div class="vote-item">
      <template v-if="activeVoting">
        <Check :model.sync="model" :name="item.name + '-' +  item.artist" :value="item.id"
               size="large" placement="right" class="vote-item__check" :disabled="disabled">
          <!-- Check label -->
          <div>
            <span class="vote-item__author">{{ item.artist }}</span>
            <span class="vote-item__title">{{ item.name }}</span>
          </div>
        </Check>
      </template>

      <template v-else>
        <div>
          <div>
            <span class="vote-item__author">{{ item.artist }}</span>
            <span class="vote-item__title">{{ item.name }}</span>
<!--            ({{item.chartAppearances}})-->
          </div>
        </div>
      </template>
    </div>
  `
})
export class VotingItem extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly activeVoting!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly disabled!: boolean

  @Prop({ type: Object, required: true })
  public readonly item!: TrackData

  @PropSync('value', { type: Boolean, required: false, default: false })
  public model!: boolean
}
