import { Component, Prop, Vue } from 'vue-property-decorator'

import { BackgroundTheme, backgroundThemeClassRegistry, getThemeClass } from '@component/_composables'

import { ImgPlacement } from './Layout.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Layout',
  template: `
    <section class="layout container-fluid" :class="themeClass">
      <!-- HEADING -->
      <div v-if="this.$slots.heading" class="row">
        <div class="d-none d-lg-block col-lg-2" />
        <div class="col-12 col-lg-5 layout__heading" >
          <slot name="heading"/>
        </div>
        <div class="d-none col-lg-5" />
      </div>
      <!-- HEADING | END -->

      <main class="row">
        <!-- MAIN CONTENT -->
        <div class="d-none d-lg-block col-lg-2" />
        <div class="col-12 col-lg-5">
          <slot />
        </div>
        <!-- MAIN CONTENT | END -->

        <!-- RIGHT COLUMN -->
        <div class="col-12 col-lg-5 layout__right-column" :class="{ 'd-none d-lg-block': !this.$slots.action }">
          <div v-if="this.$slots.action" class="layout__action">
            <slot name="action"/>
          </div>

          <div v-if="this.$slots.photo" class="d-none d-lg-block layout__photo" :class="'--' + imgPlacement">
            <slot name="photo"/>
          </div>
        </div>
        <!-- RIGHT COLUMN -->
      </main>
    </section>
  `
})
export class Layout extends Vue {
  @Prop({ type: String, required: false, default: ImgPlacement.Top })
  public readonly imgPlacement?: ImgPlacement

  @Prop({ type: String, required: false, default: BackgroundTheme.Transparent })
  private readonly theme!: BackgroundTheme

  public get themeClass (): string {
    return getThemeClass<BackgroundTheme>(this.theme, backgroundThemeClassRegistry)
  }
}
