// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 *
 * Note: This file contains global resources' definitions. Use it only for services & non-contextual endpoints.
 * Otherwise you can register your set via boot method inside module.
 */
export const resources: ResourcesRegistry = {
  process: {
    prefix: 'api',
    methods: {
      get: {
        url: 'process/{processId}',
        params: ['processId'],
        method: Methods.Get,
        auth: false
      }
    }
  }
}
