import { Component, Prop, Vue } from 'vue-property-decorator'

import { Button } from '@component/Button'
import { Theme } from '@component/_composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Error',
  components: { Button },
  template: `
    <div class="error">
      <h2 v-if="this.$slots.header" class="error__header">
        <slot name="header"/>
      </h2>

      <p class="error__message">
        <slot/>
      </p>

      <div v-if="this.$slots.action">
        <slot name="action"/>
      </div>

      <div v-if="errorRedirect">
        <h2 class="error__header">
          {{ $t('vote.error.header') }}
        </h2>

        <p class="error__message"> {{ $t('vote.error.page-not-found') }}</p>

        <a :href="mainPageLink" class="error__action">
          <Button class="d-none d-lg-block" :theme="theme.Primary"
                  :label="$t('info.back')"/>
        </a>
      </div>
    </div>`
})
export class Error extends Vue {
  @Prop({
    type: Boolean,
    required: false
  })
  public readonly errorRedirect!: boolean

  public readonly mainPageLink = process.env.VUE_APP_RADIO357_MAIN_PAGE

  public readonly theme = Theme
}
