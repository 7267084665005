// Copyright © 2021 Move Closer

import {
  IResponse,
  mapModel,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { ProcessData } from '@service/process-service'
import { ConnectorErrors, resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { Chart } from '../models/chart'
import { chartAdapterMap } from '../models/chart.adapter'
import { ChartData, ChartInfo, TrackData, IChartRepository } from '../contracts'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { topVoteExampleResponse } from '@module/voting/repositories/example'

export class ChartRepository extends Repository<ChartData, Chart> implements IChartRepository {
  protected map: MappingConfig = chartAdapterMap
  protected useAdapter = true

  protected errorMessage: string = 'Nie można wykonać żądania'

  public async getUserVotes (chartId: number): Promise<Array<TrackData> | null> {
    try {
      const response: IResponse = await this.connector.call(
        'chart',
        'loadVotes',
        { chartId },
        {},
        {}
      )
      if (!response.isSuccessful()) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response),
          {}
        )
      }

      return response.data.data
    } catch (error) {
      throw new ResourceActionFailed(
        this.errorMessage,
        ConnectorErrors.Unknown,
        {}
      )
    }
  }

  public async getChart (chartNo: string, slug: string, token: string): Promise<ChartData | null> {
    try {
      const response: IResponse = await this.connector.call(
        'chart',
        'loadChart',
        {
          slug,
          chartNo
        },
        {},
        { Authorization: `Bearer ${token}` }
      )
      if (!response.isSuccessful()) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response),
          {}
        )
      }

      if (!response.data.data) {
        return null
      }

      const mapData: ChartData = {
        chart: mapModel(response.data.data.chart, chartAdapterMap),
        results: response.data.data.results
      }

      return mapData
    } catch (error) {
      throw new ResourceActionFailed(
        error.message,
        error.status,
        {}
      )
    }
  }

  public async loadLast (slug: string): Promise<ChartData | null> {
    try {
      const response: IResponse = await this.connector.call(
        'chart',
        'loadLast',
        { slug },
        {}
      )
      if (!response.isSuccessful()) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response.status),
          {}
        )
      }

      if (!response.data.data) {
        return null
      }

      const mapData: ChartData = {
        chart: mapModel(response.data.data.chart, chartAdapterMap),
        results: response.data.data.results
      }
      return mapData
      // return mapModel(response.data.data, chartAdapterMap, false)
    } catch (error) {
      throw new ResourceActionFailed(
        error.message,
        error.status,
        {}
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public async load (slug: string): Promise<ChartInfo | null> {
    try {
      const response: IResponse = await this.connector.call(
        'chart',
        'load',
        { slug },
        {}
      )

      // response from example
      // const response: IResponse = {
      //   data: topVoteExampleResponse,
      //   isSuccessful: () => true,
      //   errors: { message: '' }
      // }

      if (!response.isSuccessful()) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response),
          {}
        )
      }

      if (!response.data.data) {
        return null
      }

      return mapModel(response.data.data, chartAdapterMap, false)
    } catch (error) {
      throw new ResourceActionFailed(
        error.message,
        error.status,
        {}
      )
    }
  }

  public async validate (payload: Array<TrackData['id']>, chartId: number): Promise<ProcessData> {
    try {
      const response: IResponse = await this.connector.call(
        'chart',
        'validate',
        { chartId },
        { tracks: payload }
      )
      if (!response.isSuccessful()) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response),
          {}
        )
      }

      return response.data as ProcessData
    } catch (error) {
      throw new ResourceActionFailed(
        this.errorMessage,
        ConnectorErrors.Unknown,
        {}
      )
    }
  }

  public async vote (payload: Array<TrackData['id']>, chartId: number): Promise<ProcessData> {
    try {
      const response: IResponse = await this.connector.call(
        'chart',
        'vote',
        { chartId },
        { tracks: payload }
      )

      if (!response.isSuccessful()) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response),
          {}
        )
      }

      return response.data as ProcessData
    } catch (error) {
      throw new ResourceActionFailed(
        this.errorMessage,
        ConnectorErrors.Unknown,
        {}
      )
    }
  }
}
