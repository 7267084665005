// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const votetrackAdapterMap: MappingConfig = {
  artist: 'artist',
  id: 'id',
  name: 'name',
  mp3Url: 'mp3_url',
  chartAppearances: 'chart_appearances'
}
