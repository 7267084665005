// Copyright © 2021 Move Closer

import { DirectiveBinding } from 'vue/types/options'
import { DirectiveOptions } from 'vue'

/**
 * Focus directive for form controls.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const focus: DirectiveOptions = {
  bind (el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value === true) {
      setTimeout(() => el.focus(), 0)
    }
  },

  update (el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value === true) {
      setTimeout(() => el.focus(), 0)
    }
  }
}
