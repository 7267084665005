import { Component, Prop } from 'vue-property-decorator'

import { AbstractInput } from '../_abstract'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Input',
  template: `
    <input v-model="_model" v-focus="autofocus"
           :id="name" :name="name"
           v-bind="{ autocomplete, disabled, placeholder, readonly, required, type }"
           class="form-control" :class="[sizeClass, validationClass]"
           @focus="() => $emit('focus')" @blur="() => $emit('blur')" @input="(e) => $emit('input', e)"/>
  `
})
export class Input extends AbstractInput {
  @Prop({ type: String, required: false, default: 'text' })
  public readonly type!: string
}
