// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  IConfiguration,
  IConnector,
  Module
} from '@movecloser/front-core'

import { ChartRepository } from './repositories/chart'
import { ChartRepositoryType, IChartRepository } from './contracts/repositories'
import { resources } from './resources'
import { routesFactory } from '@module/voting/routes'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <Katarzyna.Otto@movecloser.pl>
 */
@AppModule({
  name: 'voting',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind) => {
      bind<IChartRepository>(ChartRepositoryType).to(ChartRepository)
    }
  },
  boot: container => {
    const connector = container.get<IConnector>(ApiConnectorType)
    connector.useResources(
      resources
    )
  },
  routes: routesFactory
})
export class VotingModule extends Module {}
