import { SizeMap, SizeRegistry } from '../_composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const checkSizeRegistry: SizeRegistry = {
  [SizeMap.Large]: '--large',
  [SizeMap.Medium]: '',
  [SizeMap.Small]: '--small',
  [SizeMap.XLarge]: '--large',
  [SizeMap.XSmall]: '--small'
}
