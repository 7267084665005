// Copyright © 2021 Move Closer

import { IModuleConstructor } from '@movecloser/front-core'

import { VotingModule } from '@/modules/voting/module'
import { RootModule } from '@/modules/root/module'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const modules: IModuleConstructor[] = [
  // Here you can register new modules of the app.
  // Follow IModuleConstructor type: new() => Module
  VotingModule,
  RootModule
]
