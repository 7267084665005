// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

import { Button } from '@component/Button'
import { Inject } from '@plugin/inversify'
import { List, ListItem } from '@component/List'

import { ChartInfo as Chart } from '../../contracts'
import { InfoTypes } from './ChartInfo.contracts'
import { infoConfig } from './ChartInfo.config'

/**
 * Component to render table of information and rules about chart/voting
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ChartInfo',
  components: { List, Button },
  template: `
    <div>
    <List v-if="this.chart.id" :list="infoList" minimalist>
      <template v-slot="item">
        <div class="d-flex justify-content-between pt-3" v-if="item.data.value">
          <span class="text-uppercase fs-small">{{ $t('info.' + item.data.label) }}</span>
          <span>
              {{ dates.includes(item.data.label) && item.data.value ? dateTime.parseToFormat(item.data.value, 'DD.MM.YYYY, HH:mm') : item.data.value || '-' }}
            </span>
        </div>
      </template>
    </List>
    </div>
  `
})
export class ChartInfo extends Vue {
  @Prop({ type: Object, required: true })
  public readonly chart!: Chart

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  // Array of Information types which are display as date
  public readonly dates: string[] = [InfoTypes.StartAt, InfoTypes.EndedAt, InfoTypes.AnnouncementAt]

  /**
   * Getter to collect nemeses information to display form Chart object
   */
  public get infoList (): ListItem[] {
    const info: Record<InfoTypes, string> = { ...infoConfig }

    for (const key of Object.keys(info)) {
      if (!(key in this.chart)) {
        continue
      }

      if (key === InfoTypes.No && this.chart.no !== undefined) {
        info.no = `#${this.chart.no}`
        continue
      }

      if (key === InfoTypes.MaxVotes && (this.chart.minVotesPerUser !== undefined || this.chart.maxVotesPerUser !== undefined)) {
        info.maxVotesPerUser = `${this.chart.minVotesPerUser} - ${this.chart.maxVotesPerUser}`
        continue
      }

      info[key as keyof Record<InfoTypes, string>] = this.chart[key as keyof Chart] as string
    }

    return Object.entries(info)
      .map(([label, value]: [string, string]) => (
        {
          data: { label, value }
        }))
  }
}
