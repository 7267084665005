
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Button } from '@component/Button'
import { Layout } from '@component/Layout'
import { Picture } from '@component/Picture'

import { ChartData } from '../contracts'
import { ChartInfo } from '../components/ChartInfo'

/**
 * View displaying Information and rules about voting
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<InfoView>({
  name: 'InfoView',
  components: { Layout, Button, ChartInfo, Picture }
})
export class InfoView extends Vue {
  @Prop({ type: Object, required: false })
  public readonly chart!: ChartData | null

  public get img (): string {
    return require('../../../assets/images/photos/pasiak/pasiak.png')
  }
}

export default InfoView
