import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Card',
  template: `
    <div class="card" >
      <div v-if="this.$slots.header" class="card-header">
        <slot name="header" />
      </div>

      <div class="card-body">
        <slot />
      </div>

      <div v-if="this.$slots.footer" class="card-footer">
        <slot name="footer" />
      </div>
    </div>
  `
})
export class Card extends Vue {
}
