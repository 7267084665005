import { Component, Prop, Vue } from 'vue-property-decorator'

import { BackgroundTheme, backgroundThemeClassRegistry } from '../_composables/background'
import { getThemeClass } from '../_composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'SnackBar',
  template: `
    <div class="snack-bar" :class="themeClass" >
      <div class="snack-bar__message">
        <slot />
      </div>

      <div v-if="this.$slots.actions" class="snack-bar__action">
        <slot name="actions" />
      </div>
    </div>
  `
})
export class SnackBar extends Vue {
  @Prop({ type: String, required: false, default: BackgroundTheme.Danger })
  private readonly theme!: BackgroundTheme

  public get themeClass (): string {
    return getThemeClass<BackgroundTheme>(this.theme, backgroundThemeClassRegistry)
  }
}
