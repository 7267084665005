import { Component, Prop } from 'vue-property-decorator'

import { AbstractControl, Model } from '../_abstract'
import { getSizeClass } from '../_composables'

import { checkSizeRegistry } from './Check.config'
import { CheckType, Placement } from './Check.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'Check',
  template: `
    <div class="custom-control form-check" :class="[sizeClass]">
    <input class="custom-control-input custom-check form-check-input" :class="['custom-' + type, sizeClass, validationClass, positionClass]"
           :name="name" :id="'v-' + value" v-model="_model" :value="value"
           v-bind="{ disabled, readonly, type }" />
    <label class="form-check-label" :for="'v-' + value">
      <slot>{{ label }}</slot>
    </label>
  </div>
  `
})
export class Check extends AbstractControl {
  @Prop({ type: String, required: false })
  public readonly label!: string

  @Prop({ type: String, required: false, default: Placement.Left })
  public readonly placement?: Placement

  @Prop({ type: String, required: false, default: CheckType.Checkbox })
  public readonly type!: CheckType

  @Prop({ type: [String, Boolean, Number], required: false, default: true })
  public readonly value!: boolean | string | number

  public get positionClass (): string {
    return `--${this.placement}`
  }

  public get _model (): Model {
    if (typeof this.model === 'boolean' || this.type === 'radio') {
      return this.model
    } else if (!Array.isArray(this.model)) {
      return [this.model]
    }
    return this.model
  }

  public set _model (value: Model) {
    this.$emit('update:model', value)
  }

  public get sizeClass (): string {
    return getSizeClass(this.size, checkSizeRegistry)
  }
}
